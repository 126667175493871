import { createUseStyles } from 'react-jss';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';

export default createUseStyles(({ brandColors, breakpoints, spacing, palette }: CmsWrenchTheme) => {
  const MOBILE_BREAKPOINT = `@media (max-width: ${breakpoints.sm}px)`;

  return {
    smLogo: {
      width: '250px',
    },

    // this little bit of old school sauce will ensure that the vertical separator between
    // the SVMK logo and the company logo is _just_ slightly shorter than the company logo
    // regardless of its size... and it's compatible back to _probably_ IE6 (I don't have
    // copy lying around to see if there's a hasLayout problem...)
    companyLogoContainer: {
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: spacing[2],
        left: spacing[6],
        bottom: spacing[2],
        borderLeft: `1px solid ${brandColors.neutral.charcoal}`,
      },
      [MOBILE_BREAKPOINT]: {
        '&:before': {
          content: '',
        },
      },
    },

    companyLogo: {
      paddingLeft: spacing[6],
      marginLeft: spacing[6],
      maxWidth: '100px',
      maxHeight: '100px',
      position: 'relative',
      display: 'block',
      [MOBILE_BREAKPOINT]: {
        marginLeft: 0,
        paddingLeft: spacing[4],
      },
    },

    mainContent: {
      backgroundColor: brandColors.neutral.canvas,
    },

    embeddedLinks: {
      // slimmed down version of @wds/link styles since I can't just import those directly
      '& a': {
        color: palette.text.link,
        '&:hover, &:focus': {
          textDecoration: 'none',
        },
      },
    },

    pageWidth: {
      width: '980px',
      margin: [0, 'auto'],
      [MOBILE_BREAKPOINT]: {
        width: 'auto',
        padding: [0, spacing[6]],
        boxSizing: 'border-box',
      },
    },

    pageFooter: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      borderTop: `1px solid ${brandColors.neutral.flint}`,
      [MOBILE_BREAKPOINT]: {
        position: 'static',
        padding: [spacing[6]],
      },
    },
  };
});
