import React from 'react';
import classNames from 'classnames';
import { sanitizeString } from '@sm/utils';
import { Typography } from '@wds/typography';
import { Box } from '@wds/box';
import { Button } from '@wds/button';
import { Link } from '@wds/link';
import { LogoWithText } from '@sm/webassets/Logos';

import { EnterpriseSubdomain } from '~lib/generatedGqlTypes';

import useStyles from './useStyles';

/**
 * Formatting convenience method for returning the posessive form of a company
 * while handling undefined company names
 *
 * @param companyName The name of the company if there is one
 */
const makeCompanyNamePossessive = (companyName: string | undefined): string => (companyName ? `${companyName}'s` : '');

const CustomSubdomain = ({
  subdomainData,
  ssoSmParam,
}: {
  subdomainData: EnterpriseSubdomain;
  ssoSmParam: string | undefined;
}): React.ReactElement => {
  const { mainContent, pageWidth, smLogo, companyLogo, companyLogoContainer, pageFooter, embeddedLinks } = useStyles();
  const showSsoButton = subdomainData.showSsoLogin && ssoSmParam;

  return (
    <Box display="grid">
      <Box display="flex" py={6}>
        <Box className={classNames(pageWidth)} display="flex" alignItems="center">
          <a href="//www.surveymonkey.com/">
            <LogoWithText className={classNames(smLogo)} />
          </a>
          {subdomainData.logoUrl && (
            <div className={classNames(companyLogoContainer)}>
              <img
                src={subdomainData.logoUrl}
                alt={`${subdomainData.companyName} Logo`.trim()}
                data-testid="CustomSubdomain__Logo"
                className={classNames(companyLogo)}
              />
            </div>
          )}
        </Box>
      </Box>
      <Box py={8} className={classNames(mainContent)}>
        <Box className={classNames(pageWidth)}>
          {subdomainData.title && (
            <Box mb={8}>
              <Typography variant="hero1" data-testid="CustomSubdomain__Title" component="h1">
                <span
                  className={classNames(embeddedLinks)}
                  dangerouslySetInnerHTML={{ __html: sanitizeString(subdomainData.title) }}
                />
              </Typography>
            </Box>
          )}
          {subdomainData.description && (
            <Typography variant="body" data-testid="CustomSubdomain__Description">
              <p
                dangerouslySetInnerHTML={{ __html: sanitizeString(subdomainData.description) }}
                className={classNames(embeddedLinks)}
              />
            </Typography>
          )}
          {showSsoButton && (
            <Box mt={6}>
              <Button
                variant="ghost"
                color="secondary"
                size="lg"
                href={`/user/sso/?sm=${ssoSmParam}`}
                data-testid="CustomSubdomain__SsoButton"
              >
                Sign in to your account
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box py={8}>
        <Box className={classNames(pageWidth)}>
          <Typography variant="body">
            {subdomainData.privacyPolicyUrl && (
              <Link
                href={subdomainData.privacyPolicyUrl}
                data-testid="CustomSubdomain__PrivacyPolicyLink"
                target="_blank"
                title={`${makeCompanyNamePossessive(subdomainData.companyName)} Privacy Policy`.trim()}
              >
                {`${subdomainData.companyName} Privacy Policy`}
              </Link>
            )}
            {subdomainData.privacyPolicyUrl && subdomainData.termsOfUseUrl && ' | '}
            {subdomainData.termsOfUseUrl && (
              <Link
                href={subdomainData.termsOfUseUrl}
                target="_blank"
                data-testid="CustomSubdomain__TermsOfUseLink"
                title={`${makeCompanyNamePossessive(subdomainData.companyName)} Terms of Use`.trim()}
              >{`${subdomainData.companyName} Terms of Use`}</Link>
            )}
          </Typography>
          <Box my={4}>
            <Typography variant="bodySm" data-testid="CustomSubdomain__Trademark" component="p">
              <span
                className={classNames(embeddedLinks)}
                dangerouslySetInnerHTML={{ __html: sanitizeString(subdomainData.trademark) }}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box py={5} display="flex" justifyContent="center" className={classNames(pageFooter)}>
        <Typography variant="bodySm">
          Copyright &copy; 1999-{new Date().getFullYear()} SurveyMonkey.{' '}
          <Link href="//www.surveymonkey.com/mp/take-a-tour/">About SurveyMonkey</Link> &middot;{' '}
          <Link href="//www.surveymonkey.com/mp/legal/privacy/">Privacy Notice</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomSubdomain;
