import { GetServerSidePropsResult } from 'next';
import Head from 'next/head';
import React from 'react';
import { ThemeProvider } from 'react-jss';
import { obfuscate } from '@sm/obfuscation';

import theme, { useGlobalStyles } from '~app/styles/cmsTheme';
import CustomSubdomain from '~app/pages/CustomSubdomain/CustomSubdomain';
import OneTrustFooterScript from '~components/oneTrustScript';
import PageLayout from '~components/pageLayout';
import fetchLocaleMessages from '~helpers/fetchLocaleMessages';
import routeHandler from '~helpers/routeHandler';
import { CustomGSSPContext, LayoutData } from '~helpers/middleware/types';
import customSubdomainDataLoader, {
  CustomSubdomainServerSideData,
  CustomSubdomainServerSideProps,
} from '~helpers/pages/custom-subdomain';
import { PageWithLayout } from '~helpers/pages/types';

import { FourOhFourErrorPage } from '~app/components/Errors';

type SsoSmParam = {
  ssoSmParam: string | undefined;
};
type CustomSubdomainContext = CustomGSSPContext<
  LayoutData & CustomSubdomainServerSideProps & CustomSubdomainServerSideData
>;
type CustomSubdomainServerSidePropsResult = GetServerSidePropsResult<LayoutData & SsoSmParam>;
type CustomSubdomainPageProps = LayoutData & CustomSubdomainServerSideData & SsoSmParam;

export const getServerSideProps = async ({
  req,
  res,
  query,
}: CustomSubdomainContext): Promise<CustomSubdomainServerSidePropsResult> => {
  const handler = routeHandler({
    query,
    pageDataLoader: customSubdomainDataLoader,
    owners: '@webplatform/surveys',
    onetrustBannerEnabled: true,
  });

  await handler.run(req, res);

  const { slLanguageLocale, languageCode } = req?.smenviron;
  const translationData = await fetchLocaleMessages(slLanguageLocale, languageCode);
  const groupId = req.pageProps?.subdomainData?.groupId;
  const ssoSmParam = groupId && obfuscate(encodeURI(`g=${groupId}&source=custom_subdomain`));

  return {
    props: {
      ...req.payloads,
      ...req.pageProps,
      translationData,
      layout: {
        variant: 'SurveyMonkey',
        options: {
          pageId: 'CustomSubdomain',
          legacyWeb: 'anonweb',
          includeHeader: false,
          includeFooter: false,
          includeGTM: false,
        },
      },
      ssoSmParam,
    },
  };
};

const CustomSubdomainPage: PageWithLayout<CustomSubdomainPageProps> = ({ ...props }) => {
  const { subdomainData, ssoSmParam } = props;
  useGlobalStyles();
  return (
    <ThemeProvider theme={theme}>
      <Head>
        <link rel="canonical" href="/" />
        <title>{`${subdomainData?.companyName} & SurveyMonkey`}</title>
      </Head>
      {subdomainData && <CustomSubdomain subdomainData={subdomainData} ssoSmParam={ssoSmParam} />}
      {!subdomainData && <FourOhFourErrorPage />}
      <OneTrustFooterScript />
    </ThemeProvider>
  );
};

CustomSubdomainPage.getLayout = page => {
  const { layout, staticData, translationData } = page.props;
  return (
    <PageLayout layout={layout} staticData={staticData} translationData={translationData}>
      {page}
    </PageLayout>
  );
};

export default CustomSubdomainPage;
